import React from "react";
import {
  Container,
  SimpleGrid,
  Heading,
  Text,
  Stack,
  Box,
} from "@chakra-ui/react";
import ImageCarousel from "./ImageCarousel";
function Contact() {
  return (
    <Box bg={"brand.100"} py={32}>
      <Stack spacing={4} as={Container} maxW={"5xl"}>
        <SimpleGrid columns={{ base: 1, md: 2 }} spacing={{ base: 28, md: 12 }}>
          <ImageCarousel />
          <Stack spacing={4}>
            <Heading fontFamily={"bigCaslon"} color="black">
              Contact Us
            </Heading>
            <Text color={"gray.500"} fontSize={"lg"}>
              We are located at 69 Lincoln Blvd Suite G, in Lincoln, CA 95648
            </Text>

            <Text color={"gray.500"} fontSize={"lg"}>
              Hours of Operation:
              <br />
              Monday - Saturday: 9AM - 7PM
              <br />
              Sunday: Closed
            </Text>

            <Text color={"gray.500"} fontSize={"lg"}>
              To schedule an appointment, feel free to give us a call at (916)
              543-3881!
            </Text>
          </Stack>
        </SimpleGrid>
      </Stack>
    </Box>
  );
}

export default Contact;
