import React from "react";
import ReactDOM from "react-dom/client";
import "./styles/index.css";
import Hero from "./components/Hero";
import Services from "./components/Services";
import Footer from "./components/Footer";
import Bio from "./components/Bio";
import reportWebVitals from "./reportWebVitals";
import Contact from "./components/Contact";
import { ChakraProvider } from "@chakra-ui/react";
import { extendTheme } from "@chakra-ui/react";
import "./styles/fonts/BigCaslon/bigcaslon.css";
const theme = extendTheme({
  colors: {
    brand: {
      //100: "#2B2F33",
      100: "#FAF9F6",
      200: "#000000",
    },
  },
  fonts: {
    bigCaslon: "'BigCaslon'", // Add the custom font to the body
  },
});

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <ChakraProvider theme={theme}>
    <React.StrictMode>
      <Hero />
      <Bio />
      <Services />
      <Contact />
      <Footer />
    </React.StrictMode>
  </ChakraProvider>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
