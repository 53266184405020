import { useState } from "react";
import {
  Container,
  Heading,
  Stack,
  Text,
  Button,
  Box,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalCloseButton,
  ModalBody,
  ModalFooter,
  Image,
} from "@chakra-ui/react";
import logo from "../images/calinailslogo.png";

export default function Hero() {
  const [isOpen, setIsOpen] = useState(false);

  const handleOpenModal = () => {
    setIsOpen(true);
  };

  const handleCloseModal = () => {
    setIsOpen(false);
  };

  return (
    <Box bg={"brand.100"} maxHeight={"6xl"} paddingBottom={48}>
      <Container maxW={"5xl"} textAlign={"center"}>
        {/* <Heading fontFamily={"bigCaslon"} fontStyle={"italic"} fontWeight={700}>
          California Nails
        </Heading> */}
        <Stack textAlign={"center"} align={"center"} spacing={{ base: 8 }}>
          <Image src={logo} boxSize={"300px"}></Image>
          <Heading
            fontWeight={700}
            fontFamily={"bigCaslon"}
            fontSize={{ base: "5xl", sm: "5xl", md: "6xl" }}
            color={"brand.200"}
          >
            Finally, nail care{" "}
            <Text
              as={"span"}
              color={"pink.300"}
              fontFamily={"bigCaslon"}
              fontStyle={"italic"}
            >
              you can trust
            </Text>
          </Heading>
          <Text color={"gray.500"} fontSize={"md"}>
            Schedule an appointment with one of our wonderful nail technicians
            today!
          </Text>
          <Stack spacing={6} direction={"row"}>
            <Button
              rounded={"full"}
              px={6}
              colorScheme={"pink"}
              bg={"pink.300"}
              _hover={{ bg: "pink.400" }}
              onClick={handleOpenModal}
            >
              Contact Us
            </Button>

            {/* Modal */}
            <Modal isOpen={isOpen} onClose={handleCloseModal} size="lg">
              <ModalOverlay />
              <ModalContent>
                <ModalHeader>Contact Information</ModalHeader>
                <ModalCloseButton />
                <ModalBody>
                  <Stack spacing={4}>
                    <Text color={"gray.500"} fontSize={"lg"}>
                      We are located at 69 Lincoln Blvd Suite G, in Lincoln, CA
                      95648
                    </Text>

                    <Text color={"gray.500"} fontSize={"lg"}>
                      Hours of Operation:
                      <br />
                      Monday - Saturday: 9:30AM - 7PM
                      <br />
                      Sunday: Closed
                    </Text>

                    <Text color={"gray.500"} fontSize={"lg"}>
                      To schedule an appointment, feel free to give us a call at
                      <Text fontWeight={700} color={"black"}>
                        {" "}
                        (916) 543-3881
                      </Text>
                    </Text>
                  </Stack>
                </ModalBody>
                <ModalFooter>
                  <Button
                    color="white"
                    bg={"pink.300"}
                    onClick={handleCloseModal}
                    _hover={{ bg: "pink.400" }}
                  >
                    Close
                  </Button>
                </ModalFooter>
              </ModalContent>
            </Modal>
          </Stack>
        </Stack>
      </Container>
    </Box>
  );
}
