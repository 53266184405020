// ImageCarousel.js
import React, { useState } from "react";
import { Box, Button, Image, Flex, Text, AspectRatio } from "@chakra-ui/react";
import image1 from "../images/shopimage1.jpg";
import image2 from "../images/shopimage2.jpg";
import image3 from "../images/shopimage3.jpg";
import image4 from "../images/shopimage4.jpg";
const images = [image1, image2, image3, image4];

const ImageCarousel = () => {
  const [currentIndex, setCurrentIndex] = useState(0);

  const nextImage = () => {
    setCurrentIndex((prevIndex) => (prevIndex + 1) % images.length);
  };

  const prevImage = () => {
    setCurrentIndex(
      (prevIndex) => (prevIndex - 1 + images.length) % images.length
    );
  };

  const goToImage = (index) => {
    setCurrentIndex(index);
  };

  return (
    <Box position="relative">
      <AspectRatio ratio={4 / 3}>
        <Image
          src={images[currentIndex]}
          alt={`Image ${currentIndex + 1}`}
          boxSize="100%"
          objectFit="cover"
          borderRadius="md" // Adjust the borderRadius as needed
        />
      </AspectRatio>
      <Flex
        justify="space-between"
        align="center"
        position="absolute"
        w="100%"
        p={8}
      >
        <Button
          onClick={prevImage}
          bg={"pink.200"}
          _hover={{
            bg: "pink.300",
          }}
        >
          &lt;
        </Button>
        <Button
          onClick={nextImage}
          bg={"pink.200"}
          _hover={{
            bg: "pink.300",
          }}
        >
          &gt;
        </Button>
      </Flex>
      <Flex justify="center" position="absolute" bottom={4} w="100%">
        {images.map((_, index) => (
          <Text
            key={index}
            cursor="pointer"
            mx={1}
            fontSize="xl"
            color={index === currentIndex ? "pink.500" : "gray.500"}
            onClick={() => goToImage(index)}
          >
            &bull;
          </Text>
        ))}
      </Flex>
    </Box>
  );
};

export default ImageCarousel;
