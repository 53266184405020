import {
  Box,
  Button,
  Container,
  Flex,
  Heading,
  Stack,
  Text,
  Image,
} from "@chakra-ui/react";
import Manicure from "../images/manicure.jpg";
import Pedicure from "../images/pedicure.jpg";
import Powder from "../images/dippowder.jpg";
import GelPolish from "../images/gel.jpg";
import ColorChange from "../images/nailcolor.jpg";
import Waxing from "../images/waxing.jpg";
import AddOns from "../images/addon.jpg";
import pdf from "../Files/Cali_Nails_Jan2024.pdf";
const Card = ({ heading, description, imageUrl }) => {
  return (
    <Box
      maxW={{ base: "300px", sm: "full", md: "295px" }}
      w={"full"}
      borderWidth="1px"
      borderRadius="lg"
      borderColor="pink.200"
      overflow="hidden"
      p={5}
      boxShadow={"dark-lg"}
    >
      <Stack align={"start"} spacing={4} px="2" py="2">
        <Image
          src={imageUrl}
          alt={heading}
          w="full"
          h="150"
          objectFit="cover"
          borderRadius="md"
        />
        <Box mt={2}>
          <Heading size="md" color="black">
            {heading}
          </Heading>
          <Text mt={1} color={"gray.500"} fontSize={"sm"}>
            {description}
          </Text>
        </Box>
      </Stack>
    </Box>
  );
};

export default function Services() {
  return (
    <Box py={32} bg="brand.100">
      <Stack
        spacing={4}
        as={Container}
        maxW={"5xl"}
        textAlign={{ md: "center", base: "left" }}
      >
        <Heading color="black" fontWeight={"bold"} fontFamily={"bigCaslon"}>
          Our Services
        </Heading>
        <Text color={"gray.500"} fontSize={"lg"}>
          We take pride in offering a diverse array of nail care services, from
          meticulous manicures and pedicures to stunning nail extensions. Our
          skilled team is dedicated to providing personalized spa treatments and
          unique nail art, ensuring a top-tier experience for every client.
          (Note: Our menu is not finalized yet, the current one available to
          download is a placeholder, and does not reflect our prices at the
          moment, thank you!)
        </Text>

        <Button
          as="a"
          href={pdf}
          download="CaliNailsMenu"
          rounded={"full"}
          px={6}
          maxW={60}
          mx="auto" // Center the button
          colorScheme={"pink"}
          bg={"pink.300"}
          _hover={{ bg: "pink.400" }}
        >
          See Our Full Menu!
        </Button>
      </Stack>

      <Container maxW={"5xl"} mt={12}>
        <Flex flexWrap="wrap" gridGap={12} justify="center">
          <Card
            heading={"Manicure"}
            imageUrl={Manicure}
            description={
              "Our California Manicure features a soothing nail soak, precise shaping, and more. Upgrade to our Signature offer for an additional touch of luxury."
            }
            href={"#"}
          />
          <Card
            heading={"Pedicure"}
            imageUrl={Pedicure}
            description={
              "A choice of California, Signature, Organic, and Deluxe Organic nail treatments, ensuring a personalized and indulgent experience for every preference. Elevate your self-care with our diverse options, each crafted for a unique touch of luxury and style."
            }
            href={"#"}
          />
          <Card
            heading={"Dip Powder"}
            imageUrl={Powder}
            description={
              "From classic dipping styles to vibrant pink and white designs, we offer a personalized touch for every preference. Our services include glitter, ombre, and various full set options, ensuring a stunning and tailored finish."
            }
            href={"#"}
          />
          <Card
            heading={"Gel Polish"}
            imageUrl={GelPolish}
            description={
              "Discover flawless options with our gel polish and gel polish French manicure services at California Nails."
            }
            href={"#"}
          />
          <Card
            heading={"Color Change"}
            imageUrl={ColorChange}
            description={
              "Explore our color change choices at California Nails, including hands and toes with regular polish, hands and toes with gel polish, and French tips with gel polish for a vibrant and polished look."
            }
            href={"#"}
          />
          <Card
            heading={"Waxing"}
            imageUrl={Waxing}
            description={
              "Experience a range of waxing services at California Nails, from eyebrows to full face, full leg to bikini waxing. Choose the perfect option for your smooth, refined look."
            }
            href={"#"}
          />
          <Card
            heading={"Add-Ons"}
            imageUrl={AddOns}
            description={
              "Elevate your California Nails experience with add-ons like nail art, extra massage time, gel/powder removal, nail cuts, and specialized options for kids 10 and under. Choose from French/American styles and enjoy personalized touches during your visit."
            }
            href={"#"}
          />
        </Flex>
      </Container>
    </Box>
  );
}
