import {
  Container,
  SimpleGrid,
  Flex,
  Heading,
  Text,
  Stack,
  Box,
  Button,
  Avatar,
  useColorModeValue,
} from "@chakra-ui/react";
import Mary from "../images/MaryZoomed.jpg";
export default function Bio() {
  const instagramLink = "https://www.instagram.com/california.nails.lincoln/";

  const handleFollowInstagram = () => {
    window.open(instagramLink, "_blank");
  };
  return (
    <Box bg="brand.100">
      <Container maxW={"5xl"} py={12}>
        <SimpleGrid columns={{ base: 1, md: 2 }} spacing={10}>
          <Stack spacing={4}>
            <Text
              textTransform={"uppercase"}
              color={"pink.300"}
              fontWeight={600}
              fontSize={"sm"}
              bg="pink.100"
              p={2}
              alignSelf={"flex-start"}
              rounded={"md"}
            >
              Our Story
            </Text>
            <Heading fontFamily={"bigCaslon"} color="black">
              Meet Mary
            </Heading>
            <Text color={"gray.500"} fontSize={"lg"}>
              Phuong, more commonly known as "Mary," stands as a seasoned
              industry veteran in the realm of nail technology. With a wealth of
              experience, she brings a unique and skilled touch to California
              Nails in Lincoln, ensuring a level of expertise and dedication
              that sets the salon apart in delivering exceptional nail care
              experiences.
            </Text>
          </Stack>
          <Flex>
            <Box
              w={"full"}
              bg={useColorModeValue("white", "gray.900")}
              boxShadow={"2xl"}
              rounded={"lg"}
              p={6}
              textAlign={"center"}
            >
              <Avatar size={"xl"} src={Mary} mb={4} pos={"relative"} />
              <Heading fontSize={"xl"} fontFamily={"body"}>
                Phuong "Mary" Nguyen
              </Heading>
              <Text fontWeight={600} color={"gray.500"} mb={4}>
                @california.nails.lincoln
              </Text>

              <Stack
                align={"center"}
                justify={"center"}
                direction={"row"}
                mt={6}
              ></Stack>

              <Stack mt={8} direction={"row"} spacing={4}>
                <Button
                  flex={1}
                  fontSize={"sm"}
                  rounded={"full"}
                  bg={"pink.300"}
                  color={"white"}
                  boxShadow={
                    "0px 1px 25px -5px rgb(66 153 225 / 48%), 0 10px 10px -5px rgb(66 153 225 / 43%)"
                  }
                  _hover={{
                    bg: "pink.500",
                  }}
                  _focus={{
                    bg: "pink.500",
                  }}
                  onClick={handleFollowInstagram}
                >
                  Follow
                </Button>
              </Stack>
            </Box>
          </Flex>
        </SimpleGrid>
      </Container>
    </Box>
  );
}
